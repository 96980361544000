import { DateRange, HomeWork, Schedule, TextSnippet, GroupAdd, Cabin } from '@mui/icons-material';
import moment from 'moment';
import { v4 } from 'uuid';

export const leaveTypeCardsList = [
  {
    label: 'Leave Requests',
    path: '/portal/pages/requests/leave-requests',
    icon: DateRange,
    value: 'leave_requests',
  },
  {
    label: 'Work From Home Requests',
    path: '/portal/pages/requests/work-from-home-requests',
    icon: HomeWork,
    value: 'work_from_home_requests',
  },
  {
    label: 'Half Day Requests',
    path: '/portal/pages/requests/half-day-requests',
    icon: Schedule,
    value: 'half_day_requests',
  },
  {
    label: 'Work On Holiday Requests',
    path: '/portal/pages/requests/holiday-requests',
    icon: Cabin,
    value: 'holiday_requests',
  },
  {
    label: 'Document Requests',
    path: '/portal/pages/requests/document-requests',
    icon: TextSnippet,
    value: 'document_requests',
  },
  {
    label: 'Resource Required',
    path: '/portal/pages/requests/resource-required',
    icon: GroupAdd,
    value: 'resource_required',
  },
];

export const requestHeadings = [
  {
    id: v4(), // use renderColumn, so given a dummy id
    label: 'Interval',
    renderColumn: row => `${row?.leave_from_date} - ${row?.leave_to_date}`,
  },
  {
    id: 'is_approved',
    label: 'Status',
    isStatus: true,
  },
  {
    id: 'approved_by_team_head',
    label: 'Team Head',
    isStatus: true,
  },
  {
    id: 'decided_by',
    label: 'Actions Performed',
  },
];

export const recentRequestHeadings = [
  {
    id: v4(), // use renderColumn, so given a dummy id
    label: 'Interval',
    renderColumn: row => {
      if (row?.isDocRequest) {
        return moment(row?.created_at).format('YYYY-MM-DD');
      }

      if (row?.project) {
        return moment(row?.created_at).format('YYYY-MM-DD');
      }

      return `${row?.leave_from_date || ''} - ${row?.leave_to_date || ''}`;
    },
  },
  {
    id: 'request_type',
    label: 'Type',
    renderColumn: row => row?.request_type || row?.leave_type_name,
  },
  {
    id: 'is_approved',
    label: 'Status',
    isStatus: true,
    renderColumn: row => {
      if (row?.isDocRequest || row?.project) {
        return row?.status || row?.leave_status;
      }

      return row?.is_approved || row?.leave_status || row?.status;
    },
  },
  {
    id: 'approved_by_team_head',
    label: 'Team Head',
    isStatus: true,
    renderColumn: row => row?.approved_by_team_head || row?.team_head_action_status || '_',
  },
  {
    id: 'decided_by',
    label: 'Actions Performed',
  },
];

export const summaryHeadings = [
  {
    id: 'total_approved_half_leave',
    label: 'Half Day Leaves',
  },
  {
    id: 'total_approved_leaves',
    label: 'Approved Leaves',
  },
  {
    id: 'total_approved_w_f_h',
    label: 'Work From Home',
  },
  {
    id: 'total_remaining_leaves',
    label: 'Remaining Leaves',
  },
];

export const filterFormInitialValues = {
  userProfileId: '',
  status: '',
  leave_status: '',
  fromDate: '',
  toDate: '',
  type: '',
  employeeName: '',
  leave_type: '',
};

export const recentRequestsFilterInitialValues = {
  user_profile_id: '',
  status: '',
  no_of_days: '15',
  fromDate: '',
  toDate: '',
  request_type: '',
};
export const leavesHistoryFilterInitialValues = {
  leave_status: '',
  leave_request: '',
  form_date: '',
  to_date: '',
  user_profile_id: '',
};

export const leaveRequestsFilterInitialValues = {
  leave_status: '',
  leave_request: '',
  employee_name: '',
  form_date: '',
  to_date: '',
};

export const requestTypeOptions = [
  {
    value: 'sick leave',
    label: 'Sick Leaves',
  },
  {
    value: 'casual leave',
    label: 'Casual Leaves',
  },
  {
    value: 'emergency leave',
    label: 'Emergency Leaves',
  },
  {
    value: 'WFH',
    label: 'WFH',
  },
  {
    value: 'Half Leave',
    label: 'Half Leave',
  },
  {
    value: 'Holiday Request',
    label: 'Holiday Request',
  },
  {
    value: 'document',
    label: 'Document',
  },
  {
    value: 'resource_request',
    label: 'Resource Request',
  },
];

export const activeTabKey = 'active_tab';

export const requestTabs = [
  {
    label: 'My Leaves',
    value: 0,
  },
  {
    label: 'Leaves list',
    value: 1,
  },
];

export const docRequestTabs = [
  {
    label: 'My docs',
    value: 0,
  },
  {
    label: 'Docs list',
    value: 1,
  },
];

export const docHistoryHeadings = [
  {
    id: 'updated_at',
    label: 'Request Date',
    renderColumn: row => moment(row?.updated_at).format('DD MMM YYYY'),
  },
  {
    id: 'request_type',
    label: 'Request Type',
  },
  {
    id: 'status',
    label: 'Status',
  },
  // append "remarks" column manually when using this
  // append "file" column manually when using this
];

export const docRequestsHeadings = [
  {
    id: 'updated_at',
    label: 'Request Date',
    renderColumn: row => moment(row?.updated_at).format('DD MMM YYYY'),
  },
  {
    id: v4(),
    label: 'Employee',
    renderColumn: row => row?.user_profile_id?.employee_name,
  },
  {
    id: v4(),
    label: 'Request Type',
    renderColumn: row => row?.request_type,
  },
  {
    id: 'notes',
    label: 'Notes',
    renderColumn: row => `${row?.notes?.slice(0, 25)}...`,
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'remarks',
    label: 'Remarks',
    renderColumn: row => `${row?.remarks?.slice(0, 25) || '_'}`,
  },

  // append "actions" column manually when using this
];

export const requestDetailKeyLabels = {
  request_type: 'Type',
  is_approved: 'Status',
  reason: 'Reason',
  approved_by_team_head: 'Team Head',
  decided_by: 'Actions Performed',
  hr_remarks: 'HR Remarks',
  team_head_remarks: 'Team Head Remarks',
};

export const generalRequestDetailKeyLabels = {
  decided_by: 'Actions Performed',
  hr_remarks: 'HR Remarks',
  team_head_remarks: 'Team Head Remarks',
};

export const PENDING = 'pending';
export const UPLOADED = 'uploaded';
export const DELETED = 'deleted';
export const CANCELLED = 'cancelled';
export const APPROVED = 'approved';
export const DENIED = 'denied';

export const resourceRequestTableHeadCells = [
  {
    label: 'Date',
    id: 'created_at',
  },
  {
    label: 'Project Name',
    id: 'project_name',
  },
  {
    label: 'Resource Type',
    id: 'request_type',
  },
  {
    label: 'Reason',
    id: 'reason',
  },
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Actions',
    id: 'actions',
  },
];

export const assignLeavesTableHeadData = [
  {
    label: 'Employee Name',
    id: 'user_profile_id',
  },
  {
    label: 'Type',
    id: 'leave_type_name',
  },
  {
    label: 'Start Date',
    id: 'leave_from_date',
  },
  {
    label: 'End Date',
    id: 'leave_to_date',
  },
  {
    label: 'Remarks',
    id: 'hr_remarks',
  },
];
export const assignHolidayTableHeadData = [
  {
    label: 'Employee Name',
    id: 'user_profile_id',
  },
  {
    label: 'Type',
    id: 'leave_type_name',
  },
  {
    label: 'Start Date',
    id: 'leave_from_date',
  },
  {
    label: 'End Date',
    id: 'leave_to_date',
  },
  {
    label: 'Remarks',
    id: 'hr_remarks',
  },
];
export const assignHalfDayTableHeadData = [
  {
    label: 'Employee Name',
    id: 'user_profile_id',
  },
  {
    label: 'Type',
    id: 'leave_type_name',
  },
  {
    label: 'Start Date',
    id: 'leave_from_date',
  },
  {
    label: 'End Date',
    id: 'leave_to_date',
  },
  {
    label: 'Remarks',
    id: 'hr_remarks',
  },
];
export const assignWfhTableHeadData = [
  {
    label: 'Employee Name',
    id: 'user_profile_id',
  },
  {
    label: 'Type',
    id: 'leave_type_name',
  },
  {
    label: 'Start Date',
    id: 'leave_from_date',
  },
  {
    label: 'End Date',
    id: 'leave_to_date',
  },
  {
    label: 'Remarks',
    id: 'hr_remarks',
  },
];
export const docRequestDetailKeyLabels = {
  remarks: 'Remarks',
  notes: 'Notes',
};

export const docDetailKeyLabels = {
  request_type: 'Type',
  status: 'Status',
  remarks: 'Remarks',
  notes: 'Notes',
};

export const myLeavesTabFilterKey = 'my_leaves';

export const leavesListTabFilterKey = 'leaves_list';

export const recentRequestsFilterKey = 'recent_requests';

export const myDocsTabFilterKey = 'my_docs';

export const docsListTabFilterKey = 'docs_list';
