export const EMPLOYEE_STATUS_OPTIONS = [
  // don't change without consulting backend dev
  {
    label: 'Idle',
    value: 'idle',
  },
  {
    label: 'Working',
    value: 'working',
  },
  {
    label: 'R&D',
    value: 'research_and_development',
  },
];

export const leaveCountKeyLabels = [
  { value: 'total_leaves', label: 'Total Leaves' },
  { value: 'annual leave', label: 'Annual' },
  { value: 'sick leave', label: 'Sick' },
  { value: 'casual leave', label: 'Casual' },
  { value: 'consume_annual_leaves', label: 'Consumed Annual Leave ' },
  { value: 'consume_sick_leaves', label: 'Consumed Sick Leave ' },
  { value: 'consume_casual_leaves', label: 'Consumed Casual Leave ' },
  { value: 'total_remaining_leaves', label: 'Remaining Leave Balance' },
];
export const wfhCountKeyLabels = [
  // { value: 'total_approved_half_leave', label: 'Approved Half Day' },
  { value: 'total_approved_w_f_h', label: 'Approved WFH' },
  { value: 'total_approved_w_o_h', label: 'Approved Work On Holiday' },
];
