/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Box, Button, Card, Container, Grid, Modal, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

// API HOOKS & CUSTOM HOOK
import {
  useGetAttendanceReportsForHrQuery,
  useGetAttendanceReportsQuery,
  useGetForgetCheckOutQuery,
  useUpdateCheckOutMutation,
  useUpdateSubmitReportMutation,
} from 'services/private/users';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';

// STYLES
import { fileViewModalStyles, formModalStyles } from 'styles/mui/common/modal-styles';

// COMPONENTS & UTILS
import ModalHeader from 'containers/common/components/ModalHeader';
import { primary } from 'styles/common/colors';
import SubmitBtn from 'containers/common/components/SubmitBtn';
import { Add } from '@mui/icons-material';
import moment from 'moment';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { useSelector } from 'react-redux';
import useGetEmployeeRoles from 'customHooks/useGetEmployeeRoles';
import DailyReportsTable from './components/DailyReportsTable';
import DailyReportForm from './components/DailyReportForm';
import Analysis from './components/Analysis';
import ReportRequestForm from './components/ReportRequestForm';
import EditBreakForm from '../dashboard/hr/components/attendance/EditBreakForm';

function DailyReports() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const employee = searchParams?.get('employee');
  const userProfile = useSelector(state => state.auth.user.profile);
  const { isHRController, isSuperUser, isHRManager } = useGetEmployeeRoles();
  const isController = isHRController || isHRManager || isSuperUser;

  // CONSTANTS

  const isHrAction = isController && employee && userProfile?.id !== employee?.employee;

  const { state: attendanceData } = location;
  const {
    data: checkInData,
    isLoading: attendanceLoading,
    isFetching,
  } = useGetAttendanceReportsQuery({ attendanceId: attendanceData?.id }, { skip: isHrAction });
  const {
    data,
    isLoading: hrAttendanceLoading,
    isFetching: hrAttendanceFetching,
  } = useGetAttendanceReportsForHrQuery({ attendance_id: attendanceData?.id }, { skip: !isHrAction });
  const [updateSubmitReport, { error, isSuccess, isLoading }] = useUpdateSubmitReportMutation();
  const [updateCheckOut, { error: checkOutError, isSuccess: checkOutSuccess }] = useUpdateCheckOutMutation();
  useHandleApiResponse(checkOutError, checkOutSuccess, 'Checked Out Successfully!');
  const { data: ForgetCheckOut } = useGetForgetCheckOutQuery();
  useHandleApiResponse(error, isSuccess, 'Daily Report Submitted Successfully!');
  const [reportData, setReportData] = useState({});
  const [dailyReportFormOpen, setDailyReportFormOpen] = useState(false);
  const [requestFormOpen, setRequestFormOpen] = useState(false);
  const [editBreakFormOpen, setEditBreakFormOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleDailyReportFormOpen = () => setDailyReportFormOpen(state => !state);
  const toggleRequestFormOpen = () => setRequestFormOpen(state => !state);
  const toggleEditBreakFormOpen = () => setEditBreakFormOpen(state => !state);

  const handleSubmitReport = async () => {
    const res = await updateSubmitReport({ is_report_submitted: true, id: reportData?.id });
    setConfirmModalOpen(false);
    if (!res?.error && attendanceData?.check_out) {
      const timeFormatted = moment().format('HH:mm:ss');
      const dateFormatted = moment().format('yyyy-MM-DD');
      await updateCheckOut({ date: dateFormatted, check_out_time: timeFormatted, id: reportData?.id });
      navigate('/portal');
    }

    if (!res?.error && employee) {
      navigate(location.state.from, { replace: true });
    }
  };

  const breakData = useMemo(() => {
    const lastIndex = (checkInData?.break_time.length ?? 1) - 1;
    const latestData = checkInData?.break_time[lastIndex];
    return latestData;
  }, [checkInData?.break_time]);

  useEffect(() => {
    if (checkInData || data) {
      setReportData(isHrAction ? data : checkInData);
    }
  }, [checkInData, data]);

  useEffect(() => {
    if (ForgetCheckOut && ForgetCheckOut?.report_submitted && !ForgetCheckOut?.check_out) {
      navigate('/portal');
    }
  }, [ForgetCheckOut, ForgetCheckOut?.check_out, ForgetCheckOut?.report_submitted]);

  useEffect(() => {
    if (breakData && !breakData?.end_break_time && !ForgetCheckOut?.report_submitted) {
      toggleEditBreakFormOpen();
    }
  }, [breakData, breakData?.end_break_time, ForgetCheckOut?.report_submitted]);

  return (
    <Container>
      <Box className="mb-4">
        <Typography variant="pageTitle">Submit Report</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card className="p-4">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h5" fontWeight={600}>
                What I have Done Today?
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  onClick={toggleDailyReportFormOpen}
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  disabled={reportData?.is_report_submitted && !isHrAction}
                >
                  Add Report
                </Button>
              </Stack>
            </Stack>
            <Box>
              <DailyReportsTable
                data={reportData}
                isHrAction={isHrAction}
                loading={attendanceLoading || isFetching || hrAttendanceLoading || hrAttendanceFetching}
              />
            </Box>
            <SubmitBtn
              size="large"
              isLoading={isLoading}
              onClick={() => setConfirmModalOpen(prevState => !prevState)}
              className="mt-4 w-100"
              label="Submit"
              isDisabled={(!reportData || reportData?.is_report_submitted) && !isHrAction}
            />
          </Card>
        </Grid>

        {/* Add Daily Report Form */}
        <Modal open={dailyReportFormOpen} onClose={toggleDailyReportFormOpen}>
          <Box sx={{ ...fileViewModalStyles, width: '600px' }}>
            <ModalHeader title="Daily Task Report" onClose={toggleDailyReportFormOpen} />
            <DailyReportForm
              afterRequestSend={toggleDailyReportFormOpen}
              responseHandler={resp => {
                if (!resp.error) {
                  toggleDailyReportFormOpen();
                }
              }}
              attendanceID={reportData?.id}
              checkInData={reportData}
            />
          </Box>
        </Modal>

        {/* Create Request Report Form */}
        <Modal open={requestFormOpen} onClose={toggleRequestFormOpen}>
          <Box sx={formModalStyles}>
            <ModalHeader title="Task Report Request" onClose={toggleRequestFormOpen} />
            <ReportRequestForm
              reports={reportData?.daily_report}
              afterRequestSend={toggleRequestFormOpen}
              responseHandler={resp => {
                if (!resp.error) {
                  toggleRequestFormOpen();
                }
              }}
              attendanceID={reportData?.id}
            />
          </Box>
        </Modal>

        {/* Edit Break Form */}
        <Modal open={editBreakFormOpen} onClose={() => {}}>
          <Box sx={formModalStyles}>
            <ModalHeader title="End Your Break to Proceed" onClose={() => {}} />
            <EditBreakForm
              responseHandler={resp => {
                if (!resp.error) {
                  toggleEditBreakFormOpen();
                }
              }}
              breakData={breakData}
              breakForget
            />
          </Box>
        </Modal>

        <ConfirmationDialog
          open={isConfirmModalOpen}
          message="Are you sure you want to Submit the Daily Report?"
          onConfirm={handleSubmitReport}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
        />

        <Grid item xs={12} md={4} className="d-flex flex-column gap-3">
          <Card sx={{ backgroundColor: primary }} className="p-4">
            <Typography color="white" fontWeight={600} variant="h6">
              {'Today\'s Quote'}
            </Typography>
            <Typography mt={2} color="white" variant="body2">
              “Embrace your uniqueness, and let your true self shine.”{' '}
            </Typography>
          </Card>

          <Card className="p-4">
            <Typography variant="h6" fontWeight={600}>
              {'Today\'s Time Analysis'}
            </Typography>
            <Analysis
              productiveHours={
                reportData && (isHrAction || !reportData?.is_report_submitted) ? reportData?.productive_hours : '0h 0m'
              }
              presentHours={
                reportData && (isHrAction || !reportData?.is_report_submitted) ? reportData?.total_hours : '0h 0m'
              }
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DailyReports;
