import { commonJobsApiProvidesTag, commonOnboardingApiTag } from 'utilities/constants';
import { privateAPi } from '.';

export const usersApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    getActiveUsers: build.query({
      query: () => '/api/user/search/?status=active',
      providesTags: ['GetActiveUsers'],
    }),

    getAllUsers: build.query({
      query: () => '/api/user/search/?status=all',
      providesTags: ['GetAllUsers'],
    }),

    getFilteredEmployees: build.query({
      query: body => ({
        url: '/api/user/search/',
        method: 'GET',
        params: {
          employee_name: body?.employee_name,
          job_title: body?.job_title,
          status: body?.status || 'all',
        },
      }),
      providesTags: ['GetFilteredEmployees'],
    }),

    deleteEmployee: build.mutation({
      query: id => ({
        url: `/api/users/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetFilteredEmployees', 'GetActiveUsers', 'GetAllUsers'],
    }),

    getEmployeeById: build.query({
      query: id => ({
        url: `/api/users/${id}/`,
        method: 'GET',
      }),
      providesTags: ['GetEmployeeById'],
    }),

    updateEmployeePersonalInfo: build.mutation({
      query: body => ({
        url: `/api/users/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetEmployeeById', 'getCurrentMonthBirthdays'],
    }),

    updateUserEmploymentInfo: build.mutation({
      query: body => ({
        url: `/api/users/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetEmployeeById',
        'GetInactiveEmployees',
        'GetResignedTerminatedUsers',
        'getCurrentMonthBirthdays',
      ],
    }),

    updateUserEmploymentStatus: build.mutation({
      query: body => ({
        url: `/api/update-probation-period/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetEmployeeById',
        'GetInactiveEmployees',
        'GetResignedTerminatedUsers',
        'getCurrentMonthBirthdays',
        'GetEmployeeLeavesCount',
        'GetLeavesCount',
      ],
    }),

    updateUserAccountInfo: build.mutation({
      query: body => {
        const profile = {
          department: body.department,
          imm_super_id: body.imm_super_id || null,
          start_date: body?.start_date,
        };

        const user = {
          profile,
          user_groups: [body.group] || [],
          email: body.email,
        };

        if (body?.password) user.password = body.password;

        return {
          url: `/api/users/${body.id}/`,
          method: 'PATCH',
          body: user,
        };
      },
    }),

    getImmSupervisorByDept: build.query({
      query: department => ({
        url: `/api/ImmediateSupervisor/immediate_supervisor/?department=${department}`,
      }),
    }),

    updateEmployeeProfilePhoto: build.mutation({
      query: body => {
        const formData = new FormData();
        formData.append('photo', body?.photo, body?.photo?.name);
        formData.append('user_id', body.userId);

        return {
          url: '/api/auth/uploadPhoto/',
          method: 'PATCH',
          body: formData,
        };
      },
      invalidatesTags: ['GetAuthorizedUser', 'GetEmployeeById'],
    }),

    addUser: build.mutation({
      query: body => {
        const profile = {
          education: body?.education.map(({ id, ...rest }) => rest),
          experience: body?.experience.map(({ id, ...rest }) => rest),
          employee_name: body.employee_name,
          gender: body.gender || null,
          dob: body.dob,
          age: body.age,
          marital_status: body.marital_status || null,
          national_id: body.national_id || null,
          nationality: body.nationality || null,
          personal_mob: body.personal_mob || null,
          personal_email: body.personal_email,
          job_title: body.job_title,
          company_mob: body.company_mob,
          company_email: body.company_email,
          address: body.address,
          account_number: body.account_number,
          account_name: body.account_name,
          bank_name: body.bank_name,
          bank_address: body.bank_address,
          iban: body.iban,
          currency: body.currency,
          salary_basic: body.salary_basic,
          salary_allowance: body.salary_allowance,
          start_date: body.start_date,
          medical_insurance_num: body.medical_insurance_num || '',
          medical_insurance_expiration_date: body.medical_insurance_expiration_date || null,
          status: 'active',
          dependent: body.dependent || null,
          notes: body.notes,
          department: body.department || null,
          leave_approver_one_id: null,
          leave_approver_two_id: null,
          leave_approver_three_id: null,
          employee_status: body?.employee_status || 'working',
          employee_shift: body?.employee_shift || null,
          probation_complete_date: body?.probation_complete_date,
        };

        const payload = {
          profile,
          user_groups: body?.group ? [body.group] : [],
          password: body?.password,
          email: body?.email,
        };

        return {
          url: '/api/auth/register',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [commonJobsApiProvidesTag, commonOnboardingApiTag],
    }),

    updateUser: build.mutation({
      query: body => ({
        url: `/api/users/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetAuthorizedUser',
        'GetEmployeeById',
        commonJobsApiProvidesTag,
        commonOnboardingApiTag,
        'getCurrentMonthBirthdays',
      ],
    }),

    // ATTENDANCE
    // take them to their own respective files

    getForgetCheckOut: build.query({
      query: () => 'api/attendance/check-forget-to-check-out/',
      providesTags: ['GetForgetCheckOut'],
    }),

    getAttendanceCheckIn: build.query({
      query: () => '/api/attendance/attendance-for-employee/',
      providesTags: ['GetAttendanceCheckIn'],
    }),

    getAttendanceReports: build.query({
      query: body => ({
        url: '/api/attendance/attendance-for-employee/',
        method: 'GET',
        params: {
          attendance_id: body.attendanceId,
        },
      }),
      providesTags: ['GetAttendanceReports'],
    }),

    getAttendanceReportsForHr: build.query({
      query: params => ({
        url: '/api/attendance/attendance-for-hr/',
        method: 'GET',
        params
      }),
      providesTags: ['GetAttendanceReportsForHr'],
    }),

    updateCheckinStatus: build.mutation({
      query: body => ({
        url: '/api/attendance/employee-attendance/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    updateForgetCheckinStatus: build.mutation({
      query: body => ({
        url: '/api/attendance/forget-to-check-in/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    updateCheckInTime: build.mutation({
      query: body => ({
        url: `/api/attendance/update-check-in-time/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    updateCheckOut: build.mutation({
      query: body => ({
        url: `/api/attendance/employee-attendance/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAttendanceStatus',
        'GetAttendanceDates',
        'GetAttendanceFacts',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    getAttendanceFacts: build.query({
      query: body => ({
        url: '/api/attendance/attendance-count/',
        method: 'GET',
        params: {
          profile_id: body.profile_id,
          start_date: body.start_date,
          end_date: body.end_date,
        },
      }),
      providesTags: ['GetAttendanceFacts'],
    }),

    updateStartBreakTime: build.mutation({
      query: body => ({
        url: '/api/attendance/break-time/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetAttendanceCheckIn'],
    }),

    updateEndBreakTime: build.mutation({
      query: body => ({
        url: `/api/attendance/break-time/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetAttendanceCheckIn'],
    }),

    updateBreakTimeManually: build.mutation({
      query: body => ({
        url: `/api/attendance/break-time/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetAttendanceCheckIn', 'GetAttendanceFacts', 'GetAttendanceReports', 'GetAttendanceData'],
    }),

    getAttendanceData: build.query({
      query: body => ({
        url: '/api/attendance/employee-attendance/',
        method: 'GET',
        params: {
          ...body,
        },
      }),
      providesTags: ['GetAttendanceData'],
    }),

    getEmployeeAttendanceStatus: build.query({
      query: body => ({
        url: 'api/attendance/absent-employee/',
        method: 'GET',
        params: {
          ...body,
        },
      }),
      providesTags: ['GetEmployeeAttendanceStatus'],
    }),

    getAttendanceKpiData: build.query({
      query: body => ({
        url: '/api/attendance/employee-attendance/',
        method: 'GET',
        params: {
          ...body,
          kpi: true,
        },
      }),
      providesTags: ['GetAttendanceKpiData'],
    }),

    getProductivityData: build.query({
      query: body => ({
        url: '/api/attendance/employee-attendance/',
        method: 'GET',
        params: {
          profile_id: body?.profile_id,
          start_date: body?.start_date,
          end_date: body?.end_date,
        },
      }),
      providesTags: ['GetAttendanceData'],
    }),

    updateForgetToCheckOutAttendance: build.mutation({
      query: body => ({
        url: `/api/attendance/forget-to-check-out/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceDates',
        'GetForgetCheckOut',
        'GetAttendanceReports',
      ],
    }),

    updateForgetToCheckOutAttendanceForHr: build.mutation({
      query: body => ({
        url: `/api/attendance/update-forget-to-check-out/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceDates',
        'GetForgetCheckOut',
        'GetAttendanceReports',
        'GetAttendanceData',
      ],
    }),

    deleteAttendance: build.mutation({
      query: id => ({
        url: `/api/attendance/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceDates',
        'GetForgetCheckOut',
        'GetAttendanceReports',
      ],
    }),

    // REPORTS
    // take them to their own respective files

    getDailyReports: build.query({
      query: id => ({
        url: '/api/attendance/daily-report/',
        method: 'GET',
        params: {
          attendance__id: id,
        },
      }),
      providesTags: ['GetDailyReports'],
    }),

    getAttendanceDates: build.query({
      query: () => '/api/attendance/attendance-dates/',
      providesTags: ['GetAttendanceDates'],
    }),

    addDailyReports: build.mutation({
      query: body => ({
        url: '/api/attendance/daily-report/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetDailyReports', 'GetAttendanceCheckIn', 'GetAttendanceReports', 'GetAttendanceReportsForHr'],
    }),

    updateDailyReports: build.mutation({
      query: body => ({
        url: `/api/attendance/daily-report/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GetDailyReports', 'GetAttendanceCheckIn', 'GetAttendanceReports', 'GetAttendanceReportsForHr'],
    }),

    deleteDailyReports: build.mutation({
      query: id => ({
        url: `/api/attendance/daily-report/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetDailyReports', 'GetAttendanceCheckIn', 'GetAttendanceReports', 'GetAttendanceReportsForHr'],
    }),

    updateSubmitReport: build.mutation({
      query: body => ({
        url: `/api/attendance/report-submission/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetDailyReports',
        'GetAttendanceDates',
        'GetAttendanceData',
        'GetAttendanceCheckIn',
        'GetForgetCheckOut',
        'GetAttendanceReports',
        'GetEmployeeAdherence',
        'GetHrAdherence',
      ],
    }),

    addReportRequest: build.mutation({
      query: body => ({
        url: '/api/attendance/attendance-request/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetReportRequest',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceReports',
      ],
    }),

    getReportRequest: build.query({
      query: id => ({
        url: '/api/attendance/attendance-request/',
        method: 'GET',
        params: {
          attendance__employee__id: id,
        },
      }),
      providesTags: ['GetReportRequest'],
    }),

    updateReportRequest: build.mutation({
      query: body => ({
        url: `/api/attendance/attendance-request/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'GetReportRequest',
        'GetAttendanceCheckIn',
        'GetAttendanceData',
        'GetEmployeeAdherence',
        'GetHrAdherence',
        'GetAttendanceDates',
        'GetAttendanceReports',
      ],
    }),

  }),
});

export const {
  useGetActiveUsersQuery,
  useGetAllUsersQuery,
  useLazyGetFilteredEmployeesQuery,
  useDeleteEmployeeMutation,
  useGetEmployeeByIdQuery,
  useUpdateEmployeePersonalInfoMutation,
  useUpdateUserEmploymentInfoMutation,
  useUpdateUserEmploymentStatusMutation,
  useLazyGetImmSupervisorByDeptQuery,
  useUpdateUserAccountInfoMutation,
  useUpdateEmployeeProfilePhotoMutation,
  useAddUserMutation,
  useUpdateUserMutation,

  // ATTENDANCE
  // take them to their own respective files

  useGetForgetCheckOutQuery,
  useUpdateCheckinStatusMutation,
  useUpdateForgetCheckinStatusMutation,
  useUpdateCheckInTimeMutation,
  useUpdateCheckOutMutation,
  useGetAttendanceCheckInQuery,
  useGetAttendanceReportsQuery,
  useGetAttendanceReportsForHrQuery,
  useGetAttendanceFactsQuery,
  useUpdateStartBreakTimeMutation,
  useUpdateEndBreakTimeMutation,
  useUpdateBreakTimeManuallyMutation,
  useGetAttendanceDataQuery,
  useGetEmployeeAttendanceStatusQuery,
  useUpdateForgetToCheckOutAttendanceMutation,
  useUpdateForgetToCheckOutAttendanceForHrMutation,
  useGetProductivityDataQuery,
  useGetAttendanceKpiDataQuery,
  useDeleteAttendanceMutation,

  // REPORTS
  // take them to their own respective files

  useGetAttendanceDatesQuery,
  useGetDailyReportsQuery,
  useAddDailyReportsMutation,
  useUpdateDailyReportsMutation,
  useDeleteDailyReportsMutation,
  useUpdateSubmitReportMutation,
  useAddReportRequestMutation,
  useGetReportRequestQuery,
  useUpdateReportRequestMutation,
} = usersApi;
